import React from "react";

import Loader from "components/Loader";
import PluralField from "components/thing/fields/PluralField";
import thingsService from "services/things";
import namespacesService from "services/namespaces";
import {addThing} from "services/views";
import {TOAST_SHOWN, MODAL_SHOWN, MODAL_HIDDEN} from "state/actions";
import * as fmt from "formatters";
import {isDefined} from "utils";

// The amount of results which may be expected to be rendered in the
// chooser dialog
const ACCEPTABLE_RESULTS_COUNT = 10;

const RelationField = ({inputId, initialState, saveState, formStateKey, prop, store}) => {
  const modalKey = `${inputId}:modal`;
  const modalOpen = store.getState("modal", modalKey);
  const modalRef = React.useRef();
  const service = thingsService(store);
  const linkedSchema = React.useMemo(
    () =>
      namespacesService(store)
        .currentNamespace()
        .schemas.find(({contents: {name}}) => name === prop.relationTarget),
    // eslint-disable-next-line
    [prop.relationTarget]
  );
  const complete = isDefined(linkedSchema) && !service.isIncomplete(linkedSchema);
  const loading = isDefined(linkedSchema) && service.anyFetchOngoing(linkedSchema);
  const loadMoreDisabled = complete || loading;

  const searchInput = React.useRef();
  const [search, setSearch] = React.useState("");
  React.useEffect(() => {
    if (searchInput.current) {
      searchInput.current = search;
    }
    // eslint-disable-next-line
  }, []);

  const handleLoadError = () =>
    TOAST_SHOWN.dispatch(store, {
      icon: "danger",
      text: "No se pudieron cargar los archivos; inténtelo más tarde",
      timeout: 5000,
    });
  React.useEffect(() => {
    if (
      isDefined(linkedSchema) &&
      service.isOld(linkedSchema) &&
      !service.anyFetchOngoing(linkedSchema)
    ) {
      service.forceFetch(linkedSchema).catch(handleLoadError);
    } else if (
      isDefined(linkedSchema) &&
      !service.anyFetchOngoing(linkedSchema) &&
      service.isIncomplete(linkedSchema) &&
      (
        store.getState("resource", service.resourceKey(linkedSchema), "data", "items") ??
        []
      ).filter((thing) => service.thingMatches(linkedSchema, thing, search)).length <
        ACCEPTABLE_RESULTS_COUNT
    ) {
      service.fetch(linkedSchema).catch(handleLoadError);
    }
  });

  const input = React.useRef();

  if (!isDefined(linkedSchema)) {
    return (
      <span className="text-muted">
        No es posible cargar {prop.propName.toLowerCase()}
      </span>
    );
  }

  return (
    <>
      {prop.singular && (
        <label htmlFor={inputId}>
          <strong>{prop.propName}</strong>
        </label>
      )}
      <div className="d-flex flex-row">
        <button
          type="button"
          id={inputId}
          className={[
            "btn btn-block relation-field-selector text-truncate mr-2",
            initialState ? "text-left" : "text-center",
          ].join(" ")}
          title={`Seleccionar ${prop.propName.toLowerCase()}`}
          onClick={MODAL_SHOWN.dispatcher(store, modalKey)}
          ref={input}
        >
          {initialState
            ? fmt.formatThing(namespacesService(store).currentNamespace())(initialState)
            : `Seleccionar ${prop.propName.toLowerCase()}`}
        </button>
        <button
          type="button"
          className="btn btn-light filefield-button"
          title={`Quitar ${prop.propName.toLowerCase()}`}
          onClick={() => saveState(null)}
        >
          <span>
            <i className="fas fa-times"></i>
          </span>
        </button>
      </div>
      <div
        className={["modal", modalOpen ? "fade show d-block" : null]
          .filter((c) => c)
          .join(" ")}
        tabIndex="-1"
        role="dialog"
        ref={modalRef}
        onClick={(e) =>
          e.target === modalRef.current ? MODAL_HIDDEN.dispatch(store, modalKey) : null
        }
      >
        <div className="modal-dialog modal-dialog-scrollable modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <input
                type="text"
                className="form-control"
                placeholder={`Buscar ${prop.propName.toLowerCase()}...`}
                onChange={(evt) => setSearch(evt.target.value)}
                ref={searchInput}
              />
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={MODAL_HIDDEN.dispatcher(store, modalKey)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {loading && <Loader />}
              {/* Display the things available to lookup */}
              {(
                store.getState(
                  "resource",
                  service.resourceKey(linkedSchema),
                  "data",
                  "items"
                ) ?? []
              )
                .filter((thing) => service.thingMatches(linkedSchema, thing, search))
                .map((thing, index, arr) => (
                  <div className="thing-item px-0" key={`thing-${thing.id}`}>
                    <button
                      type="button"
                      className={[
                        "btn",
                        "btn-block",
                        "text-truncate",
                        index === 0 ? "first" : null,
                        index === arr.length - 1 ? "last" : null,
                      ]
                        .filter((c) => c)
                        .join(" ")}
                      title={fmt.formatThing(namespacesService(store).currentNamespace())(
                        thing
                      )}
                      onClick={() => {
                        saveState(thing);
                        MODAL_HIDDEN.dispatch(store, modalKey);
                      }}
                    >
                      {fmt.formatThing(namespacesService(store).currentNamespace())(
                        thing
                      )}
                    </button>
                  </div>
                ))}
              {/* Show a "load more" button, or a warning that no files exist */}
              {store.getState(
                "resource",
                service.resourceKey(linkedSchema),
                "data",
                "total"
              ) === 0 ? (
                <p className="lead mt-1 text-center">
                  No hay{" "}
                  {(
                    linkedSchema.contents.thingNamePlural ||
                    `${linkedSchema.contents.thingName}s`
                  ).toLowerCase()}
                  .
                </p>
              ) : (
                <button
                  type="button"
                  className="btn btn-light btn-block btn-lg"
                  disabled={loadMoreDisabled}
                  onClick={() => service.fetch(linkedSchema).catch(handleLoadError)}
                >
                  {complete ? "—" : "Cargar más"}
                </button>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-block btn-primary"
                onClick={() => {
                  MODAL_HIDDEN.dispatch(store, modalKey);
                  if (isDefined(linkedSchema)) {
                    addThing(store, linkedSchema, [formStateKey, prop.key]);
                  }
                }}
              >
                NUEVO
              </button>
              <button
                type="button"
                className="btn btn-block btn-secondary"
                onClick={MODAL_HIDDEN.dispatcher(store, modalKey)}
              >
                CANCELAR
              </button>
            </div>
          </div>
        </div>
      </div>
      {modalOpen && <div className="modal-backdrop fade show" />}
    </>
  );
};

export default (props) => <PluralField component={RelationField} {...props} />;
